<template>
<div>
  <b-container fluid style="background-image: url('/img/ai-background.jpg'); background-repeat: no-repeat; background-size: cover; height: 478px; margin: 0px;">
    <b-row>
      <b-col style="color: white;">
        <b-container>
          <b-row>
            <b-col>
              <h1 class="kstyle" style="font-size: 42px; font-weight: 500; margin-top: 200px;">Welcome to Controls</h1>
              <p style="font-size: 18px;">Curated by a network of specialists,
                Controls provides a reference framework of control standards and best practices.
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-4" style="padding-bottom: 100px;">
    <b-row class="link-cards">
      <b-col lg="12">
        <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">Get Started</h2>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'ControlsControls' }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-folder class="home-icon"></b-icon-folder></div>
            <div class="link-card-link p-3 m-0" style="text-align: center;">Browse controls</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'ControlsMatrix', params: {id: 154 }}" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-grid-3x3 class="home-icon"></b-icon-grid-3x3></div>
            <div class="p-3 m-0" style="text-align: center;">Explore controls matrix</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'ControlsNews', params: {id: 154 }}" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-newspaper class="home-icon"></b-icon-newspaper></div>
            <div class="p-3 m-0" style="text-align: center;">Access latest news</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'ControlsInsights' }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-binoculars class="home-icon"></b-icon-binoculars></div>
            <div class="p-3 m-0" style="text-align: center;">Explore insights</div>
          </div>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-5">
        <h2 style="font-size: 20px; font-weight: bold;">About Controls</h2>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col class="text-center" lg="4" sm="12">
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/lucasocelewicz.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Lucas Ocelewicz</strong></p>
              <p class="mb-0"><small>KPMG Regulatory Horizon Global Lead</small></p>
              <p class="mb-5"><small>KPMG in the UK</small></p>
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/emilylyttle.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Emily Lyttle</strong></p>
              <p class="mb-0"><small>KPMG Regulatory Horizon Controls Lead</small></p>
              <p><small>KPMG in the UK</small></p>
            </b-col>
            <b-col lg="8" sm="12">
              <div>
                <p>The Controls page on the KPMG Regulatory Horizon platform provides access to KPMG UK’s library of control reference standards.</p>
                <p>Our KPMG UK specialists have created a library of control standards designed to help delivery efficiency and ultimately lead to better client outcomes. Each control standard has a control objective and associated expectations for what the control should include. Control descriptions have not been included as these should be specific to each client and aligned to their control owners, systems and business processes (please refer to our thought-leadership materials for further guidance on control description quality).</p>
                <p>This reference library is critical for delivering control assessments, control testing, and control framework implementation engagements. The library has been organised by our risk taxonomy,  thematic categories, relevant sectors and domains, as well as by our internal projects. This structure allows quick and efficient access to relevant control standards which can be downloaded to support client engagements.</p>
                <p>While we are committed to maintaining the high-quality control standards in our library, always consult with the relevant specialists to ensure you are using the most up-to-date, comprehensive, and relevant reference materials. Controls information should never be relied upon without specialist oversight.</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container fluid style="background-color: #1E49E2; color: white; margin: 0px;">
    <b-row>
      <b-col>
        <b-container fluid style="padding-top: 100px; padding-bottom: 100px">
          <b-row>
            <b-col>
              <b-container>
              <b-row>
                <b-col lg="4">
                  <img src="/img/controls-utopia.jpg" style="width: 100%; height: auto;" />
                </b-col>
                <b-col lg="8" sm="12">
                  <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">Controls transformation</h2>
                  <div>
                    <p>KPMG’s vision for our clients is a control framework
                      with a clear purpose that can effectively and
                      efficiently reduce risk and enable impactful
                      decision-making. We can help your organization
                      on its transformation journey toward a future state
                      where you can benefit from:</p>
                    <ul>
                      <li>A simplified and sustainable risk and control environment.</li>
                      <li>Controls that are preventative, automated and self-evidencing.</li>
                      <li>Automation of controls, KCI monitoring and controls testing.</li>
                      <li>Increased productivity and cost savings, freeing up capacity by reducing the effort required to maintain and manage superfluous controls.</li></ul>
                    <p>Today, the drive for growth alongside changing
                      technologies, risks, regulations and ways of
                      working has caused some organizations to lose
                      control of their controls.</p>
                    <p>Now is the time for your business to optimize and
                      automate your risk and control framework to drive
                      efficiency, effectiveness and sustainability while
                      aiming to reduce risk.</p>
                  </div>
                </b-col>
              </b-row>
              <b-row style="margin-top: 100px;">
                <b-col class="text-center" lg="4" sm="12">
                  <div>
                    <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/narindersingh.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
                  </div>
                  <p class="mb-0"><strong>Narinder Singh</strong></p>
                  <p class="mb-0"><small>Risk Transformation Lead</small></p>
                  <p class="mb-5"><small>KPMG in the UK</small></p>
                </b-col>
                <b-col class="text-center" lg="4" sm="12">
                  <div>
                    <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/sarahward.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
                  </div>
                  <p class="mb-0"><strong>Sarah Ward</strong></p>
                  <p class="mb-0"><small>Controls Transformation Lead</small></p>
                  <p><small>KPMG in the UK</small></p>
                </b-col>
                <b-col class="text-center" lg="4" sm="12">
                  <div>
                    <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/nehaljilka.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
                  </div>
                  <p class="mb-0"><strong>Nehal Jilka</strong></p>
                  <p class="mb-0"><small>Controls Transformation Lead</small></p>
                  <p><small>KPMG in the UK</small></p>
                </b-col>
              </b-row>
            </b-container>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-4" style="padding-top: 100px;">
    <b-row v-if="aipost">
      <b-col>
        <h2 class="mb-2" style="font-size: 20px; font-weight: bold;">AI on Controls</h2>
        <p class="mb-3">Summary of recent developments generated automatically by Gen AI - may contain mistakes</p>
      </b-col>
    </b-row>
    <b-row class="shadow px-3 py-4 bg-white mb-5" v-if="aipost">
      <b-col>
        <h4 class="mb-3" style="font-size: 18px; font-weight: bold;">{{ aipost.name }}</h4>
        <div class="mb-3">{{ aipost.created_at.slice(0,10) }}</div>
        <div>
          <markdown :content="aipost.body" />
        </div>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-4">
    <b-row>
      <b-col lg="8" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">KPMG Insights</h2>
        <p>Recent KPMG publications</p>
        <div v-if="!loadingArticles && articles.length > 0">
          <b-container class="shadow p-5" style="background-color: #1E49E2; color: white;">
            <b-row>
              <b-col lg="8" sm="12">
                <div><b-link :to="{ name: 'ControlsInsight', params: {id: article.id }}" style="color: white;"><strong>{{article.title}}</strong></b-link></div>
                <div class="mb-2"><small>{{moment(article.approved_at).format('YYYY-MM-DD')}} by {{ article.approved_by }}</small></div>
                <div>{{ article.summary }}</div>
              </b-col>
              <b-col class="text-center" lg="4" sm="12">
                <div>
                  <div :style="article.style"></div>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div v-for="item in articles" :key="'articles'+item.id" class="my-4">
            <b-container class="shadow p-3 bg-white">
              <b-row>
                <b-col>
                  <div><b-link :to="{ name: 'ControlsInsight', params: {id: item.id }}"><strong>{{item.title}}</strong></b-link></div>
                  <div class="mb-2"><small>{{moment(item.approved_at).format('YYYY-MM-DD')}} by {{ item.approved_by }}</small></div>
                  <div>{{ item.summary }}</div>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="mt-4 mb-2"><b-link :to="{ name: 'ControlsInsights' }"><strong>More Insights</strong></b-link></div>
        </div>
        <div class="my-3" v-if="loadingArticles">
          <b-spinner label="loading insights..." />
          loading insights...
        </div>
        <h2 class="mt-5" style="font-size: 20px; font-weight: bold;">Contact us</h2>
        <p>Provide feedback, request features or support</p>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col>
              <contact-form subject="KPMG Controls Feedback" />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">News</h2>
        <p>Recent industry updates</p>
        <div class="shadow py-4 px-3 bg-white" v-if="!loading">
          <div class="my-3" v-for="(item, index) in news" :key="'news-' + index">
            <div>
              <router-link :to="{ name: 'ControlsNewsItem', params: { id: item.id }}">
                <div><strong>{{item.name}}</strong></div>
                <div><small>{{ item.date.slice(0,10) }} - {{ item.countryName }} - {{ item.publisher }}</small></div>
              </router-link>
            </div>
          </div>
          <div>
            <router-link class="mt-4 mb-2" :to="{ name: 'ControlsNews', params: {id: 154 }}"><strong>More News</strong></router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import moment from 'moment'

import contactForm from '@/components/ContactForm.vue'
import Markdown from '@/components/Markdown.vue'
import { BIconBinoculars, BIconFolder, BIconGrid3x3, BIconNewspaper } from 'bootstrap-vue'

function getUsernameFromEmail (email) {
  const match = email.match(/^[^@]+/)
  return match ? match[0].replace(/\./g, '') : null
}

export default {
  name: 'AiHome',
  components: {
    BIconBinoculars,
    BIconFolder,
    BIconGrid3x3,
    BIconNewspaper,
    contactForm,
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      aipost: null,
      article: null,
      articles: [],
      loading: true,
      loadingArticles: true,
      moment: moment,
      news: []
    }
  },
  created: async function () {
    this.$store.commit('setBrand', 'Controls')
    document.title = "KPMG Controls"
    const aiposts = await this.$Amplify.API.post('cosmos', `/aiposts/topics/limit/1/offset/0`, { body: { topics: [36, 115] } })
    if (aiposts.length > 0) {
      this.aipost = aiposts[0]
    }
    this.news = await this.$Amplify.API.post('cosmos', '/news/topics/standardformat/limit/12', { body: { topics: [36, 115] } })
    this.loading = false
    const articles = await await this.$Amplify.API.post('cosmos', `/articles/topics/limit/5`, { body: { topics: [36, 115] } })
    articles.forEach(x => {
      const img = getUsernameFromEmail(x.approved_by)
      x.img = img
      x.style = `margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/${img}.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;`
    })
    const article = articles.shift()
    this.article = article
    this.articles = articles
    this.loadingArticles = false
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.carousel-caption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure the div takes full height of the slide */
  padding-top: 1rem; /* Optional: add some padding to the top */
}

.carousel-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure the div takes full height of the slide */
  padding-top: 1rem; /* Optional: add some padding to the top */
}

.home-icon {
  color: white;
  height: 90px;
  width: 90px;
  margin: 20px;
}
.link-card {
  font-size: 16px;
  font-weight: bold;
}

.link-card div:hover {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.link-cards a:hover {
    text-decoration: none;
}
</style>
